<template>
  <section>
    <!-- <div v-if="isModalOpen" class="modal"> -->
    <Transition name="modal">
      <div v-if="propValue" class="modal-mask">
        <div class="modal-container" ref="modal">
          <div class="modal-header">
            <slot name="header"><h3>Edit User Profile</h3></slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="card h-100">
                    <div class="card-body user-profile">
                      <div class="row gutters">
                        <div
                          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                        >
                          <h6 class="mb-2 text-primary">Personal Details</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label for="fullName">Full Name</label>
                            <input
                              v-model="state.vu_fname"
                              type="text"
                              class="form-control"
                              id="fullName"
                              placeholder="Enter full name"
                              v-bind:class="{
                                invaliddata: v$.vu_fname.$error == true,
                              }"
                            />
                            <span v-if="v$.vu_fname.$error">
                              {{ v$.vu_fname.$errors[0].$message }}
                            </span>
                          </div>
                        </div>
                        <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                         <div class="form-group">
                            <label for="email">Email</label>
                            <input
                              v-model="state.vu_email"
                              type="email"
                              class="form-control"
                              id="email"
                              placeholder="Enter email ID"
                              v-bind:class="{
                                invaliddata: v$.vu_email.$error == true,
                              }"
                            />
                            <span v-if="v$.vu_email.$error">
                              {{ v$.vu_email.$errors[0].$message }}
                            </span>
                          </div> 
                        </div> -->
                        <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label for="phone">Phone</label>
                            <input
                              v-model="state.vu_phone"
                              type="tel"
                              class="form-control"
                              id="phone"
                              placeholder="Enter phone number"
                              v-bind:class="{
                                invaliddata: v$.vu_phone.$error == true,
                              }"
                            /> -->
                        <!-- <input
                              v-model="phone"
                              type="tel"
                              class="form-control"
                              id="phone"
                              placeholder="Enter phone number"
                            /> -->
                        <!-- <span v-if="v$.vu_phone.$error">
                              {{ v$.vu_phone.$errors[0].$message }}
                            </span>
                          </div> 
                        </div>-->
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label for="dob">Updage Image</label>

                            <div class="clear"></div>
                            <img
                              v-if="previewImage == ''"
                              :src="'../../public/img/team/team-1.jpg'"
                              class="img-thumbnail rounded-circle"
                              alt="Service"
                              style="height: 65px; width: 65px"
                            />

                            <img
                              v-if="previewImage !== ''"
                              :src="previewImage"
                              class="img-thumbnail rounded-circle"
                              alt="Service"
                              style="
                                height: 65px;
                                width: 65px;
                                margin-right: 15px;
                              "
                            />
                            <i
                              class="fas fa-camera"
                              @click="$refs.imageFile.click()"
                            ></i>
                            <input
                              class="ml"
                              type="file"
                              accept="image/jpeg"
                              @change="uploadImage"
                              ref="imageFile"
                              style="display: none"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row gutters">
                        <div
                          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                        >
                          <div class="button-area">
                            <div class="rightbtn">
                              <button
                                type="button"
                                id="submit"
                                name="submit"
                                class="btn btn-secondary"
                                @click="cancelModal"
                              >
                                Cancel
                              </button>
                            </div>
                            <div class="leftbtn">
                              <button
                                type="button"
                                id="submit"
                                name="submit"
                                class="btn btn-primaryy"
                                @click="submitForm()"
                              >
                                Update<span></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </div>

          <div class="modal-footer"></div>
        </div>
      </div>
    </Transition>
  </section>
</template>
<script>
import useVuelidate from "@vuelidate/core";
//import { required, minLength, email, numeric } from "@vuelidate/validators";
import { required, minLength } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import { mapActions, mapGetters } from "vuex";
import { toast } from "vue3-toastify";
import gsap from "gsap";

export default {
  name: "userProfileModal",
  props: {
    propValue: Boolean, // Define the prop and its type
  },
  setup() {
    const state = reactive({
      vu_fname: "",
      //vu_email: "",
      //vu_phone: "",
      //vu_date: "",
    });

    const rules = computed(() => {
      return {
        vu_fname: { required, minLength: minLength(3) },
        //vu_email: { required, email },
        //vu_phone: { required, numeric },
        // vu_date: { required, minLength: minLength(3) },
      };
    });

    const v$ = useVuelidate(rules, state);
    return {
      state,
      v$,
    };
  },
  data() {
    return {
      userId: "",
      previewImage: "",
      phone: "",
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
  },
  mounted() {
    this.userInfo();
  },
  watch: {
    propValue(newVal) {
      if (newVal) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
  },
  methods: {
    ...mapActions(["updateUserProfileAction"]),
    submitForm() {
      this.v$.$validate().then(() => {
          if (!this.v$.$error) {
            this.parentCloseModal();
          }
        })
        .catch((error) => {
          alert("form failed validation");
          console.log(error);
        });
    },

    parentCloseModal() {
      this.callApiforSendingData();
      this.$emit("pparentCloseModal");
    },
    async callApiforSendingData() {
      try {
        console.log("Hellos");
        const formData01 = new FormData();
        formData01.append("id", this.userId);
        formData01.append("name", this.state.vu_fname);
        // formData01.append("email", this.state.vu_email);
        // formData01.append("phone", this.phone);
        if (this.$refs.imageFile.files[0]) {
          formData01.append("profile_image", this.$refs.imageFile.files[0]);
        }
        console.log(formData01, "this is");
        let response = await this.updateUserProfileAction(formData01);

        if (response.status == 200) {
          this.$emit("userDate");
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            timeout: 5000,
            duration: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: true,
            icon: true,
            rtl: false,
          });
        }
      } catch (error) {
        this.error = error.response;
      }
    },
    cancelModal() {
      this.$emit("pparentCloseModal");
    },
    zoomIn() {
      gsap.to(
        this.$refs.modal,
        // { scale: 3, opacity: 0 },
        { duration: 0.9, scale: 0.2, opacity: 1 },
        { scale: 1, opacity: 1, duration: 0.9, ease: "power2.out" }
      );
    },
    zoomOut() {
      gsap.to(this.$refs.modal, {
        scale: 0.2,
        opacity: 0,
        duration: 0.3,
        ease: "power2.in",
        onComplete: () => this.$emit("pparentCloseModal"),
      });
      this.$emit("pparentCloseModal");
    },
    userInfo() {
      
      // let user_data = JSON.parse(this.getCurrentUser);
      // let user_data = this.getCurrentUser;
      let user_data = JSON.parse(localStorage.getItem("user"))

      this.state.vu_fname = user_data.name;
      this.state.vu_email = user_data.email;
      this.state.vu_phone = user_data.phone;
      this.userId = user_data.id;
      this.previewImage =
        "https://trackpal.co/trackpal/" +
        user_data.profile_image;
      //console.log(this.getCurrentUser.name, "UserName");
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },
  },
};
</script>
<style>
.toast-wrapper {
  position: fixed;
  width: 100%;
  top: 20px;
}
.toast {
  padding: 20px;
  color: white;
  background: #ff0062;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin: 0 auto;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.9s ease;
}

.modal-container {
  width: 900px;
  margin: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.9s ease;
  border-radius: 8px;
}

.modal-header h3 {
  margin-top: 0;
  color: #b61e84;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
.leftbtn {
  float: left;
}
.rightbtn {
  float: right;
}
.button-area {
  margin-top: 15px;
}
.form-control:focus {
  border-color: #b61e84;
  box-shadow: 0 0 0 0.25rem rgba(253, 13, 173, 0.25);
}
.user-profile h6.text-primary {
  color: #b61e84 !important;
}
.user-profile .form-group {
  margin-bottom: 15px;
}
.user-profile .form-group label {
  color: #e52579;
}
.user-profile .btn-primaryy {
  background: #b61e84;
  color: #fff;
  padding-left: 40px;
  padding-right: 40px;
}
.user-profile .btn-primaryy:hover {
  background: #f32879;
}
.user-profile .form-group span {
  color: red;
}
.form-group img {
  float: left;
}
.form-group .fas {
  font-size: 30px;
  margin-top: 15px;
  float: left;
  cursor: pointer;
}
</style>
