<template>
  <section>
    <Navbar02 />
    <section>
      <!-- Start Page Title Section -->
      <div class="page-title-area item-bg1">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>Privacy Policy</h2>
                <ul>
                  <li><a href="index-2.html">Home</a></li>
                  <li>Privacy Policy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Page Title Section -->
    </section>

    <!-- Start Privacy Policy Section -->
    <section class="privacy-section ptb-100">
      <div class="container">
        <div class="privacy-policy-info">
          <h3>Last updated December 15, 2023</h3>
          <p>
            This privacy notice for TrackPal (&quot;we,&quot; &quot;us,&quot; or
            &quot;our&quot;), describes how and why we might collect, store,
            use, and/or share (&quot;process&quot;) your information when you
            use our services (&quot;Services&quot;), such as when you:
          </p>
          <ul>
            <li>
              Visit our website at
              <a href="https://trackpal.co/">https://trackpal.co</a>, or any
              website of ours that links to this privacy notice
            </li>
            <li>
               Download and use our mobile application (TrackPal), or any other
              application of ours that links to this privacy notice
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <p>
            Questions or concerns? Reading this privacy notice will help you
            understand your privacy rights and choices. If you do not agree with
            our policies and practices, please do not use our Services. If you
            still have any questions or concerns, please contact us at
            <a href="mailto:info@trackpal.co">info@trackpal.co.</a>
          </p>
          <h3>SUMMARY OF KEY POINTS</h3>
          <p>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.
          </p>
          <p>
            What personal information do we process?<br />
            When you visit, use, or navigate our Services, we may process
            personal information depending on how you interact with us and the
            Services, the choices you make, and the products and features you
            use. Learn more about personal information you disclose to us.
          </p>
          <p>
            Do we process any sensitive personal information?<br />
            We do not process sensitive personal information.<br />
            Do we receive any information from third parties?<br />
            We do not receive any information from third parties.
          </p>
          <p>
            How do we process your information?<br />
            We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention,
            and to comply with law. We may also process your information for
            other purposes with your consent. We process your information only
            when we have a valid legal reason to do so. Learn more about how we
            process your information.
          </p>
          <p>
            In what situations and with which parties do we share personal
            information?<br />
            We may share information in specific situations and with specific
            third parties. Learn more about when and with whom we share your
            personal information.<br />
            How do we keep your information safe?<br />
            We have organizational and technical processes and procedures in
            place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information. Learn more about how we
            keep your information safe.
          </p>
          <p>
            What are your rights?<br />
            Depending on where you are located geographically, the applicable
            privacy law may mean you have certain rights regarding your personal
            information. Learn more about your privacy rights.
          </p>
          <p>
            Want to learn more about what we do with any information we
            collect?<br />
            Review the privacy notice in full.
          </p>

          <h3>TABLE OF CONTENTS</h3>
          <ol>
            <li>
              <a href="#tab01" class="clickable" data-target="tab01"
                >WHAT INFORMATION DO WE COLLECT?</a
              >
            </li>
            <li>
              <a href="#tab02" class="clickable" data-target="tab02"
                >HOW DO WE PROCESS YOUR INFORMATION?</a
              >
            </li>
            <li>
              <a href="#tab03" class="clickable" data-target="tab03"
                >WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a
              >
            </li>
            <li>
              <a href="#tab04" class="clickable" data-target="tab04"
                >DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a
              >
            </li>
            <li>
              <a href="#tab05" class="clickable" data-target="tab05"
                >HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a
              >
            </li>
            <li>
              <a href="#tab06" class="clickable" data-target="tab06"
                >IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a
              >
            </li>
            <li>
              <a href="#tab07" class="clickable" data-target="tab07"
                >HOW LONG DO WE KEEP YOUR INFORMATION?</a
              >
            </li>
            <li>
              <a href="#tab08" class="clickable" data-target="tab08"
                >HOW DO WE KEEP YOUR INFORMATION SAFE?</a
              >
            </li>
            <li>
              <a href="#tab09" class="clickable" data-target="tab09"
                >WHAT ARE YOUR PRIVACY RIGHTS?</a
              >
            </li>
            <li>
              <a href="#tab10" class="clickable" data-target="tab10"
                >DO WE MAKE UPDATES TO THIS NOTICE?</a
              >
            </li>
            <li>
              <a href="#tab11" class="clickable" data-target="tab11"
                >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a
              >
            </li>
            <li>
              <a href="#tab12" class="clickable" data-target="tab12"
                >HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?</a
              >
            </li>
          </ol>

          <h3 id="tab01" class="target-block">
            1. WHAT INFORMATION DO WE COLLECT?
          </h3>
          <p>Personal information you disclose to us</p>
          <ul>
            <li>
              In Short: We collect personal information that you provide to us.
            </li>
          </ul>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p>
            Personal Information Provided by You. The personal information that
            we collect depends on the context of your interactions with us and
            the Services, the choices you make, and the products and features
            you use. The personal information we collect may include the
            following:
          </p>
          <ul>
            <li>phone numbers</li>
            <li>names</li>
            <li>email addresses</li>
            <li>usernames</li>
            <li>passwords</li>
            <li>contact preferences</li>
          </ul>
          <p><strong>Sensitive Information.</strong> We do not process sensitive information.</p>
          <p>
            Social Media Login Data. We may provide you with the option to
            register with us using your existing social media account details,
            like your Facebook, Google, Apple, or other social media account. If
            you choose to register in this way, we will collect the information
            described in the section called &quot;HOW DO WE HANDLE YOUR SOCIAL
            LOGINS?&quot; below.
          </p>
          <p>
            <strong> Application Data.</strong> If you use our application(s), we also may collect
            the following information if you choose to provide us with access or
            permission:
          </p>
          <ul>
            <li>
              <strong>Geolocation Information.</strong> We may request access or permission to
              track location-based information from your mobile device, either
              continuously or while you are using our mobile application(s), to
              provide certain location-based services. If you wish to change our
              access or permissions, you may do so in your device's settings.
            </li>
            <li>
              <strong>Contact Synchronization.</strong> Track Pal allows users to sync their phonebook to enhance their experience by helping them discover contacts who are already using the app and invite them on trips. When you grant permission, the app securely accesses your contact list, identifies registered users, and facilitates seamless trip coordination. 
              To improve functionality and provide a better user experience, we store contact information in a secure database deployed on our server. We take appropriate measures to protect this data and do not share it with third parties. You have control over your data and can manage or revoke this permission at any time through your device settings.
            </li>
            <li>
              <strong>Push Notifications.</strong> We may request to send you push notifications
              regarding your account or certain features of the application(s).
              If you wish to opt out from receiving these types of
              communications, you may turn them off in your device's settings.
            </li>
          </ul>
          <p>
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting, and for our
            internal analytics and reporting purposes.
          </p>
          <ul>
            <li>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </li>
          </ul>
          <h3 id="tab02" class="target-block">
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </h3>
          <p>
            In Short: We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </p>
          <p>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>
          <p>
            To facilitate account creation and authentication and otherwise
            manage user accounts. We may process your information so you can
            create and log in to your account, as well as keep your account in
            working order.
          </p>
          <h3 id="tab03" class="target-block">
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </h3>
          <p>
            In Short: We may share information in specific situations described
            in this section and/or with the following third parties.
          </p>
          <p>
            We may need to share your personal information in the following
            situations:
          </p>
          <ul>
            <li>
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </li>
            <li>
              When we use Google Maps Platform APIs. We may share your
              information with certain Google Maps Platform APIs (e.g., Google
              Maps API, Places API). We use certain Google Maps Platform APIs to
              retrieve certain information when you make location-specific
              requests. This includes: Loccation; and other similar information.
              A full list of what we use information for can be found in this
              section and in the previous section titled &quot;HOW DO WE PROCESS
              YOUR INFORMATION?&quot; We obtain and store on your device
              (&quot;cache&quot;) your location. You may revoke your consent
              anytime by contacting us at the contact details provided at the
              end of this document. The Google Maps Platform APIs that we use
              store and access cookies and other information on your devices. If
              you are a user currently in the European Economic Area (EU
              countries, Iceland, Liechtenstein, and Norway) or the United
              Kingdom, please take a look at our Cookie Notice.
            </li>
          </ul>
          <h3 id="tab04" class="target-block">
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </h3>
          <p>
            In Short: We may use cookies and other tracking technologies to
            collect and store your information.<br />
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>
          <h3 id="tab05" class="target-block">
            5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </h3>
          <p>
            In Short: If you choose to register or log in to our Services using
            a social media account, we may have access to certain information
            about you.
          </p>
          <p>
            Our Services offer you the ability to register and log in using your
            third-party social media account details (like your Facebook,
            Google, or Apple logins). Where you choose to do this, we will
            receive certain profile information about you from your social media
            provider. The profile information we receive may vary depending on
            the social media provider concerned, but will often include your
            name, email address, profile picture, as well as other information
            you choose to make public on such a social media platform.
          </p>
          <p>
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant Services. Please note that we do not
            control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend
            that you review their privacy notice to understand how they collect,
            use, and share your personal information, and how you can set your
            privacy preferences on their sites and apps.
          </p>
          <h3 id="tab06" class="target-block">
            6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
          </h3>
          <p>
            In Short: We may transfer, store, and process your information in
            countries other than your own.
          </p>
          <p>
            Our servers are located in. If you are accessing our Services from
            outside, please be aware that your information may be transferred
            to, stored, and processed by us in our facilities and by those third
            parties with whom we may share your personal information (see
            &quot;WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
            INFORMATION?&quot; above), in and other countries.
          </p>
          <p>
            If you are resident in the European Economic Area (EEA), United
            Kingdom (UK), or Switzerland, then these countries may not
            necessarily have data protection laws or other similar laws as
            comprehensive as those in your country. However, we will take all
            necessary measures to protect your personal information in
            accordance with this privacy notice and applicable law.
          </p>
          <h3 id="tab07" class="target-block">
            7. HOW LONG DO WE KEEP YOUR INFORMATION?
          </h3>
          <p>
            In Short: We keep your information for as long as necessary to
            fulfill the purposes outlined in this privacy notice unless
            otherwise required by law.
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
          <h3 id="tab08" class="target-block">
            8. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </h3>
          <p>
            In Short: We aim to protect your personal information through a
            system of organizational and technical security measures.
          </p>
          <p>
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
          <h3 id="tab09" class="target-block">
            9. WHAT ARE YOUR PRIVACY RIGHTS?
          </h3>
          <p>
            In Short: You may review, change, or terminate your account at any
            time.
          </p>
          <p>
            Withdrawing your consent: If we are relying on your consent to
            process your personal information, which may be express and/or
            implied consent depending on the applicable law, you have the right
            to withdraw your consent at any time. You can withdraw your consent
            at any time by contacting us by using the contact details provided
            in the section &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot;
            below.
          </p>
          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <p><strong>Account Information</strong></p>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p>
            Cookies and similar technologies: Most Web browsers are set to
            accept cookies by default. If you prefer, you can usually choose to
            set your browser to remove cookies and to reject cookies. If you
            choose to remove cookies or reject cookies, this could affect
            certain features or services of our Services.
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at <a href="mailto:info@trackpal.co">info@trackpal.co</a>
          </p>
          <h3 id="tab10" class="target-block">
            10. DO WE MAKE UPDATES TO THIS NOTICE?
          </h3>
          <p>
            In Short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated &quot;Revised&quot; date and
            the updated version will be effective as soon as it is accessible.
            If we make material changes to this privacy notice, we may notify
            you either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <h3 id="tab11" class="target-block">
            11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </h3>
          <p>
            If you have questions or comments about this notice, you may email
            us at <a href="mailto:info@trackpal.co">info@trackpal.co</a> or
            contact us by post at:
          </p>
          <p>
            TrackPal, 526 Melrose Street, Water Mill, 11976 New York, United
            States
          </p>
          <h3 id="tab12" class="target-block">
            12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h3>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please fill out and submit a
            data subject access request.
          </p>
        </div>
      </div>
    </section>
    <!-- End Privacy Policy Section -->
    <!-- Start Contact Info Section -->
    <!-- <section class="contact-info-wrapper bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h6 class="sub-title">Find Us</h6>
              <h2>Contact Info</h2>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>USA Headquarter</h5>
              <p>
                304 NW St Homestead, Florida, Melrose Street, Water Mill, 76B
                Overlook Drive Chester, PA 19013, Flemingsburg USA.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>New York Office</h5>
              <p>
                1540 Pecks Ridge Tilton Rd Flemingsburg, Kentucky(KY), 4104188
                Fulton Street Blackwood, NJ 08012, London.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="contact-info-content">
              <h5>Panama Office</h5>
              <p>
                103 Richard Ave Ashville, Ohio, Water Mill,3468 16th Hwy
                Pangburn, Arkansas(AR), Charolais Ashville, Virginia, Panama.
              </p>
              <a href="tel:12345678">080 707 555-321</a>
              <a href="mailto:demo@example.com">demo@example.com</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Contact Info Section -->

    <!-- Start Map Section -->
    <!-- <div class="map-area">
      <div class="map-content">
        <div class="map-canvas" id="contact-map"></div>
      </div>
    </div> -->
    <!-- End Map Section -->
    <Footer />
  </section>
</template>

<script>
import { ref } from "vue";
// import { onMounted } from 'vue'
import Navbar02 from "../components/Navbar02.vue";
import Footer from "../components/Footer.vue";
export default {
  components: {
    Navbar02,
    Footer,
  },
  setup() {
    const icons = ref([
      { name: "alternate_email", text: "by email" },
      { name: "local_phone", text: "by phone" },
      { name: "local_post_office", text: "by post" },
      { name: "local_fire_department", text: "by smoke signal" },
    ]);

    return { icons };
    // onMounted(() => {

    // })
  },

  mounted() {
    // Your logic when component is mounted
    //document.addEventListener("DOMContentLoaded", function () {
    // Get all the clickable links
    var links = document.querySelectorAll(".clickable");

    // Add click event listener to each link
    links.forEach(function (link) {
      link.addEventListener("click", function () {
        // Prevent the default action of the anchor tag
        //event.preventDefault();

        // Get the target block ID from the data-target attribute
        let targetBlockId = this.getAttribute("data-target");

        // Remove 'active' class from all target blocks
        var targetBlocks = document.querySelectorAll(".target-block");
        targetBlocks.forEach(function (block) {
          block.classList.remove("active");
        });

        // Add 'active' class to the target block associated with the clicked link
        var targetElement = document.getElementById(targetBlockId);
        console.log(targetElement, "Target");
        targetElement.classList.add("active");
      });
    });
    // });
  },
};
</script>

<style>
.contact ul {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 400px;
  margin: 60px auto;
}
.contact li {
  list-style-type: none;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  line-height: 1.5em;
}
/* h3[id^="tab"] {
  padding-top: 70px;
} */
/* .clicked {
  padding-top: 70px;
} */
/* CSS for the targeted blocks */
.target-block {
  /* Your CSS attributes for the targeted blocks */
  padding-top: inherit; /* Initially hide all blocks */
}

.target-block.active {
  padding-top: 70px; /* Show only the active (clicked) block */
}
</style>
