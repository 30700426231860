<template>
  <section>
    <!-- Start Navbar Section -->
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-darkk">
      <div class="container">
        <div class="col-md-6">
          <!-- <a class="navbar-brand" href="#"> -->
          <div class="techvio-responsive-menu">
            <div class="logo">
              <!-- <a href="#" @click="$router.push('/')"> -->
              <a href="#" @click="pageRefresh">
                <img
                  src="../../public/img/logo.png"
                  class="showd"
                  alt="logo"
                  id="img01"
                />
                <img
                  src="../../public/img/logo-black.png"
                  class="hided"
                  alt="logo"
                  id="img02"
                />
              </a>
            </div>
          </div>
          <!--</a> -->
        </div>
        <div class="col-md-6">
          <div class="about-btn-boxx float-right">
            <div class="dropdown" @mouseenter="showDropdown" @mouseleave="hideDropdown" >
            <a class="default-btn-three" 
            v-if="userr" >
               
               <div v-if="userPic == null">
                <i class="fas fa-user"></i> {{ cUserr }} 
              </div>
              <div v-else class="userbox">
                <div class="imgProfilebox">
                  <img
                    :src="userPicUrl+'/'+ cuserPic"
                    :alt="cUserr "
                  />
                </div>
                <p>{{cUserr }}</p>
              </div> 
              <sapn></sapn>
               </a>
               <a class="default-btn-three" 
               v-else >
               <i class="fas fa-user"></i>Hello Guest
               <sapn></sapn>
               </a>
            <!-- <a class="default-btn" v-else @click="logout"
              >{{ btnText }}<span></span
            ></a> -->
            <ul v-if="isDropdownVisible" class="dropdown-menu" >
             <div v-if="userr">
                <li v-if="dropdownOptions == 'UserProfile'">
              <router-link to="/" 
                ><i class="fas fas fa-house"></i>Home</router-link
              >
              </li >
              <li  v-else><router-link to="/user-profile"><i class="fas fas fa-address-card"></i>User Profile</router-link></li>
              </div>
              
              
             
              
              <!-- <li v-if="isLoggedIn"></li> -->
             
              
              <li><a href="#" @click="handleLoginLogoutt()"><i  class="fas fas fa-right-from-bracket"></i>{{ this.$store.state.btnText }} </a></li>
           
            
           </ul>
          

  </div>
          </div>
          
          
          
        </div>
      </div>
    </nav>

    <!-- End Navbar Section -->
  </section>
</template>

<script>
import router from "@/router";
import { mapGetters } from "vuex";
export default {
  name: "Navbar",
  data() {
    return {
      isLoggedIn: false, // Initial login status
      isDropdownVisible: false,
      userLoggedin:{},
      cUser:'Hello Guest',
      userPic:'',
      userr:false,
      dropdownOptions:'',
      currUser:{},
    };
  },
  computed:{
    ...mapGetters(["getImgPath", "getCurrentUser"]), // Using Vuex mapGetters shorthand
    userPicUrl(){
      return this.getImgPath; // OR this.$store.getters.getImgPath
    },
    cUserr(){     
      
      return this.getCurrentUser.name;
    },
    cuserPic(){     
      //const userImg = JSON.parse(localStorage.getItem("user"))
      
      return this.getCurrentUser.profile_image;
    }

  },

  mounted() {
    this.currUser=JSON.parse(localStorage.getItem("user"))
    const storedloggedin = localStorage.getItem("isLoggedIn");
    this.dropdownOptions = router.currentRoute.value.name // The name of the current route
    if (storedloggedin) {
      this.userLoggedin = JSON.parse(localStorage.getItem("user")) || {};
      this.isLoggedIn = storedloggedin;
           
      console.log(this.isLoggedIn, "new value");
      console.log(this.userLoggedin, "new User");
      console.log(this.cUser, "new CUser");
      if(this.isLoggedIn){
        this.userr = true
        if (this.userr) {
      this.cUser =  this.userLoggedin.name; 
      this.userPic = this.userLoggedin.profile_image
        }
    }else{
      this.cUser =  "ddd" 
    }

      //this.$store.state.btnText === "Logout";
      this.$store.dispatch("setlogout");
    }

    console.log(this.userPic, "image path")
    
  },
  methods: {




    handleLoginLogoutt() {
      window.addEventListener("DOMContentLoaded", () => {
        window.scrollTo(0, 0);
      });

      if (this.$store.state.btnText === "Logout") {
        window.scroll(0, 0);
        router.push("/user-login");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        this.isLoggedIn = false;
        //this.$router.push("/user-login");
        this.$store.dispatch("logout");
      }
      window.scroll(0, 0);
      router.push("/user-login");
    },

    handleLoginLogout() {
      if (this.isLoggedIn) {
        console.log(this.isLoggedIn);
        // Perform logout actions
        router.push("/user-login");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        this.isLoggedIn = false;
        //this.$store.dispatch("setlogout");

        // Redirect to login page if needed
      } else if (!this.isLoggedIn) {
        // Perform login actions
        //localStorage.setItem("isLoggedIn", "true");
        this.isLoggedIn = true;

        // Redirect to the desired page after login
        router.push("/");
      }
    },

    toggleView() {
      this.isLoggedIn = !this.isLoggedIn;
    },

    pageRefresh() {
      //this.$router.go(0);
      this.$router.push("/");
      // this.$router.go(0);
    },

    showDropdown() {
      this.isDropdownVisible = true;
    },
    hideDropdown() {
      this.isDropdownVisible = false;
    }
  },
};
</script>

<style scoped>
.navbg {
  background: #320879 !important;
}
.default-btn {
  cursor: pointer;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-btn {
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  width: 150px;
  padding: 0;
  list-style: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: block !important;
}

.dropdown-menu li {
  padding: 10px;
}

.dropdown-menu li a {
  text-decoration: none;
  color: #333;
  display: block;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.default-btn:hover,
.default-btn:focus {
  color: #333 !important;
}
.userbox {
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
}
.imgProfilebox {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.fas{margin-right: 8px;}
</style>
